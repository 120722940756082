const timestampToTime = (timestamp, type) => {
    if (isEmpty(timestamp)) return '';
    let date = new Date(timestamp.toString().length === 10 ? timestamp * 1000 : timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    // let s = `: ${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`;
    // console.log(Y + M + D + h + m + s);
    if (type === 'time') {
        return Y + M + D + h + m
    } else if (type === 'noYearTime') {
        return M + D + h + m
    } else if (type === 'date') {
        return h + m;
    } else {
        return Y + M + D;
    }
}
/**
 * 日期转时间戳
 * @param {string} dateString
 * @returns
 */
const dateToTimestamp = (dateString, type = '') => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
    }

    const timestampInMillis = date.getTime();
    let timestampInSeconds = Math.floor(timestampInMillis / 1000); // 或者使用 Math.round() 进行舍入
    if (type === 'zero') {
        timestampInSeconds = timestampInSeconds - 8 * 60 * 60;
    } else if (type === 'tonight') {
        timestampInSeconds = timestampInSeconds + 16 * 60 * 60;
    }

    return timestampInSeconds;
}

/**
 * base64编码
 * @param {string} str
 * @returns
 */
const base64_encode = (str) => {
    var c1, c2, c3;
    var base64EncodeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    var i = 0, len = str.length, string = '';

    while (i < len) {
        c1 = str.charCodeAt(i++) & 0xff;
        if (i == len) {
            string += base64EncodeChars.charAt(c1 >> 2);
            string += base64EncodeChars.charAt((c1 & 0x3) << 4);
            string += "==";
            break;
        }
        c2 = str.charCodeAt(i++);
        if (i == len) {
            string += base64EncodeChars.charAt(c1 >> 2);
            string += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
            string += base64EncodeChars.charAt((c2 & 0xF) << 2);
            string += "=";
            break;
        }
        c3 = str.charCodeAt(i++);
        string += base64EncodeChars.charAt(c1 >> 2);
        string += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
        string += base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
        string += base64EncodeChars.charAt(c3 & 0x3F)
    }
    return string
}

/**
 * 获取当前时间距离零点的时间戳
 * @param string} time
 * @returns
 */
const timeToZeroPoint = (time) => {
    let hour = time.slice(0, time.indexOf(":")),
        minute = time.slice(time.indexOf(":") + 1);
    return hour * 60 * 60 + minute * 60;
}

/**
 * 获取当前时间的时间戳
 */
const getNowTimestamp = () => {
    return Math.round(new Date().getTime() / 1000);
}


/**
 * 判断传入的参数是否是字符串类型
 * @param val 需要判断的参数
 * @returns 返回当前传入的参数是否是字符串
 */
const isString = val => Object.prototype.toString.call(val) === '[object String]';
/**
 * 判断传入的参数是否是数字类型
 * @param val 需要判断的参数
 * @returns 返回当前传入的参数是否是数字
 */
const isNumber = val => Object.prototype.toString.call(val) === '[object Number]';
/**
 * 判断传入的参数是否是布尔类型
 * @param val 需要判断的参数
 * @returns 返回当前传入的参数是否是布尔
 */
const isBoolean = val => Object.prototype.toString.call(val) === '[object Boolean]';
/**
 * 判断传入的参数是否是函数类型
 * @param val 需要判断的参数
 * @returns 返回当前传入的参数是否是函数
 */
const isFunction = val => Object.prototype.toString.call(val) === '[object Function]';
/**
 * 判断传入的参数是否是对象类型
 * @param val 需要判断的参数
 * @returns 返回当前传入的参数是否是对象
 */
const isObject = val => Object.prototype.toString.call(val) === '[object Object]';
/**
 * 判断传入的参数是否是数组类型
 * @param val 需要判断的参数
 * @returns 返回当前传入的参数是否是数组
 */
const isArray = val => Object.prototype.toString.call(val) === '[object Array]';

const forEachValue = (obj, fn) => Object.keys(obj).forEach(key => fn(obj[key], key));

/**
 * 判断参数是否为空
 * @param val 判断的参数
 * @returns 返回是否为空
 */
const isEmpty = val => {
    // 判断空对象
    if (JSON.stringify(val) === "{}") return true;
    // 判断空数组
    if (JSON.stringify(val) === "[]") return true;
    // 判断空字符串
    if (val === "") return true;
    // 判断undefined
    if (val === undefined) return true;
    // 判断undefined
    if (val === null) return true;
    return false;
}

/**
 * 深拷贝
 * @param val
 * @param weakMap
 * @returns 深拷贝后的值
 */

const deepClone = obj => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    let clone;
    if (Array.isArray(obj)) {
        clone = [];
        for (let i = 0; i < obj.length; i++) {
            clone[i] = deepClone(obj[i]);
        }
    } else {
        clone = {};
        for (let key in obj) {
            // eslint-disable-next-line no-prototype-builtins
            if (obj.hasOwnProperty(key)) {
                clone[key] = deepClone(obj[key]);
            }
        }
    }

    return clone;
}

const deepMerge = (target, ...sources) => {
    if (!sources.length) {
        return target;
    }

    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return deepMerge(target, ...sources);
}
/**
 * 获取当前时间当周某个星期的时间戳
 */

const returnCurrentWeekDate = (source, number = 1, type = 's', time = '00:00:00') => {
    if (!isNumber(number)) throw new Error("目标日期请传入数字");
    if (number < 1 || number > 7) throw new Error("请选择正确范围内的日期，1-7");
    if (type !== 's' && type !== 'ms') throw new Error("请传入正确的时间戳单位，s:以秒为单位 ms:以毫秒为单位");
    // 当前日期时间戳
    const sourcePointDate = (new Date(new Date(source).toLocaleDateString()).getTime());
    // 获取目标时间数组
    const targetTimeArray = time.split(":");
    let targetTimeDate = 0;
    // 当前日期星期
    let sourceWeek = new Date(sourcePointDate).getDay();
    // 如果sourceWeek等于0.则表示当前时间为周日，默认赋值为6
    if (sourceWeek === 0) sourceWeek = 7;
    let targetDate = 0;
    // 判断传入需要获取的星期和当前时间的星期大小
    if (number > sourceWeek) {
        // 如果需要获取的星期大于当前时间星期，则加上当前时间戳
        targetDate = sourcePointDate + ((number - sourceWeek) * 24 * 60 * 60 * 1000);
    } else if (number < sourceWeek) {
        targetDate = sourcePointDate - ((sourceWeek - number) * 24 * 60 * 60 * 1000);
    } else {
        targetDate = sourcePointDate;
    }
    // 获取目标星期需要展示的时间秒数
    for (let i in targetTimeArray) {
        if (i === "0") {
            targetTimeDate += targetTimeArray[0] * 60 * 60;
        } else if (i === "1") {
            targetTimeDate += targetTimeArray[1] * 60
        } else if (i === "2") {
            targetTimeDate += targetTimeArray[2] * 1
        }
    }
    return type == 's' ? targetDate / 1000 + targetTimeDate : targetDate + targetTimeDate * 1000;
}
/**
 * 返回数字数组之和
 * @param {number[]} arr 
 */
const returnArrayAdd = (arr) => arr.reduce((prevVal, currentVal) => prevVal + currentVal);


export default {
    isEmpty,
    timestampToTime,
    base64_encode,
    timeToZeroPoint,
    isString,
    isNumber,
    isBoolean,
    isFunction,
    isObject,
    isArray,
    deepClone,
    returnCurrentWeekDate,
    dateToTimestamp,
    deepMerge,
    getNowTimestamp,
    forEachValue,
    returnArrayAdd
}
